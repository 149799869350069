import { useEffect } from "react";
import { useSetState, useAsync, useToggle } from "react-use";
import { isEmpty, isNil } from "lodash";

import { DSAlert, DSAlertDisplay, DSAlertType } from "@skillup/ui";

import request from "helpers/request";
import useAuth from "hooks/useAuth";

import { AuthRoutes } from "api/types";

import Logo from "./Logo";
import styles from "./LoginViewStyles.module.scss";
import Dialog from "components/Dialog";

interface Props {
  redirect?: string;
}

const Password = ({ redirect }: Props) => {
  const { login: loginFromAuth, setLogin, setPassword, setPasswordPolicy, setStep } = useAuth();

  const [{ login, password, submit, hasError }, setState] = useSetState({
    login: loginFromAuth ?? "",
    password: "",
    submit: false,
    hasError: false,
  });
  const [isPasswordModalOpen, togglePasswordModal] = useToggle(false);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setState({ submit: true });
  };

  function handlePasswordLost() {
    togglePasswordModal(true);
  }

  const {
    value: success,
    loading,
    error,
  } = useAsync(async (): Promise<boolean> => {
    if (submit) {
      const { data } = await request<AuthRoutes["POST"]["/v1/password"]["response"]>({
        target: "AUTH",
        data: { login, password, redirectUrl: redirect },
        method: "POST",
        url: "/password",
      });

      const { REACT_APP_PUBLIC_APP_URL } = process.env;

      if ("nextStep" in data) {
        setLogin(login);
        setPassword(password);
        setPasswordPolicy(data.policy);
        setStep(data.nextStep);
        return true;
      }

      if (data.credentials.persistStrategy === "localStorage") {
        window.localStorage.setItem("X-Auth-Token", data.token);
        window.sessionStorage.removeItem("X-Auth-Token");
      } else if (data.credentials.persistStrategy === "sessionStorage") {
        window.sessionStorage.setItem("X-Auth-Token", data.token);
        window.localStorage.removeItem("X-Auth-Token");
      } else {
        window.sessionStorage.removeItem("X-Auth-Token");
        window.localStorage.removeItem("X-Auth-Token");
      }

      if (data.redirectUrl) {
        window.location.href = data.redirectUrl;
      } else if (data.credentials?.activeTrainingOrganization) {
        window.location.href = `${REACT_APP_PUBLIC_APP_URL}admin-organisme/`;
      } else if (data.credentials?.activeCompany?.userScope?.includes("admin")) {
        window.location.href = `${REACT_APP_PUBLIC_APP_URL}responsable/`;
      } else {
        window.location.href = `${REACT_APP_PUBLIC_APP_URL}collaborateur/`;
      }

      return true;
    }

    return false;
  }, [submit]);

  useEffect(() => setState({ hasError: hasError || !isNil(error) }), [hasError, error, setState]);

  if (loading || success) {
    return (
      <div className={styles.view}>
        <div className={styles.box}>
          <Logo />
          <h1>Veuillez patienter</h1>
          <p>Récupération des paramètres de connexion au service en cours.</p>
        </div>
      </div>
    );
  }

  if (hasError) {
    return (
      <div className={styles.view}>
        <div className={styles.box}>
          <Logo />
          <h1>Se connecter</h1>
          <p>Saisissez le mot de passe associé à votre identifiant de connexion</p>
          <DSAlert
            className={styles.alert}
            type={DSAlertType.ERROR}
            display={DSAlertDisplay.INLINE}
          >
            Identifiant ou mot de passe invalide, vérifiez votre saisie ou veuillez contacter votre
            service RH pour valider votre accès.
          </DSAlert>
          <form onSubmit={onSubmit}>
            <input
              type="text"
              placeholder="Identifiant"
              autoFocus={isEmpty(loginFromAuth)}
              onChange={({ currentTarget: { value } }) => setState({ login: value, submit: false })}
              defaultValue={login}
            />
            <input
              type="password"
              placeholder="Mot de passe"
              autoFocus={!isEmpty(loginFromAuth)}
              onChange={({ currentTarget: { value } }) =>
                setState({ password: value, submit: false })
              }
              defaultValue={password}
            />
            <p className={styles.comment}>
              Si vous n’avez pas l’habitude de saisir un mot de passe pour la connexion,
              assurez-vous d’avoir correctement saisi votre identifiant.
            </p>
            <div className={styles.actions}>
              <button
                type="submit"
                disabled={isEmpty(login) || isEmpty(password) || submit === true}
              >
                Valider
              </button>
            </div>
          </form>
          <div className={styles.bottom}>
            <span className={styles.link} onClick={handlePasswordLost}>
              Mot de passe oublié
            </span>
          </div>
        </div>
        {isPasswordModalOpen && <PasswordDialog onClose={() => togglePasswordModal(false)} />}
      </div>
    );
  }

  return (
    <div className={styles.view}>
      <div className={styles.box}>
        <Logo />
        <h1>Se connecter</h1>
        <p>Saisissez le mot de passe associé à votre identifiant de connexion :</p>
        <form onSubmit={onSubmit}>
          <input
            type="text"
            placeholder="Identifiant"
            autoFocus={isEmpty(loginFromAuth)}
            onChange={({ currentTarget: { value } }) => setState({ login: value, submit: false })}
            defaultValue={login}
          />
          <input
            type="password"
            placeholder="Mot de passe"
            autoFocus={!isEmpty(loginFromAuth)}
            onChange={({ currentTarget: { value } }) =>
              setState({ password: value, submit: false })
            }
          />
          <p className={styles.comment}>
            Si vous n’avez pas l’habitude de saisir un mot de passe pour la connexion, assurez-vous
            d’avoir correctement saisi votre identifiant.
          </p>
          <div className={styles.actions}>
            <button type="submit" disabled={isEmpty(login) || isEmpty(password) || submit === true}>
              Se connecter
            </button>
          </div>
        </form>
        <div className={styles.bottom}>
          <span className={styles.link} onClick={handlePasswordLost}>
            Mot de passe oublié
          </span>
        </div>
      </div>
      {isPasswordModalOpen && <PasswordDialog onClose={() => togglePasswordModal(false)} />}
    </div>
  );
};

function PasswordDialog({ onClose }: { onClose: () => void }) {
  return (
    <Dialog title="Mot de passe oublié" onClose={onClose}>
      Pour réinitialiser votre mot de passe, merci de vous rapprocher de votre responsable RH.
    </Dialog>
  );
}

export default Password;
